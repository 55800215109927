/*-----------common-css----------*/
*, ::after, ::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html{
  overflow-x: hidden;
}
body{
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'League Spartan', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0 !important;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
textarea{
  font-family: 'League Spartan', sans-serif;
}
h1, h2, h3, h4, h5, h6, p, span, a, li, input{
  font-family: 'League Spartan', sans-serif;
}
h1, h2, h3, h4, h5, h6{
  margin: 0 0 15px;
}
h2{
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
p{
  font-size: 16px;
  line-height: 24px;
}
a{
  cursor: pointer;
  text-decoration: none;
}
img{
  /* border-radius: '50%'; */
  max-width: 100%;
  height: 100%;
}
ul{
  padding: 0;
}
ul li{
  list-style: none;
}
.container{
  width: 100%;
  max-width: 1240px;
  margin: auto;
  padding: 0 15px;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.col{
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.cmn_btn{
  background-color: #EDCD1F;
  min-width: 6rem;
  max-width: 8rem;
  padding: 0 1rem;
  height: 3rem;
  line-height: 3rem;
  color: #ffffff;
  text-align: center;
  border-radius: 20px 0 20px 0;
  display: block;
  font-weight: 600;
  margin-top: 30px;
}
.cmn_btn:hover, .cmn_btn:focus{
  background: #000000;
}
.bar {
  height: 4px;
  width: 85px;
  background: #EDCD1F; 
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 16px
}

.form-control {
  width: 100%;
  height: 52px;
  padding: 0 15px;
  line-height: 52px;
  color: #000000;
  background-color: #ffffff;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: unset !important;
  transition: .5s;
  font-size: 15px;
  font-weight: 400;
  border-radius: 4px;
  margin-bottom: 15px;
  box-sizing: border-box;
}
input[type="submit"] {
  background: #EDCD1F;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  padding: 0 15px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
}
/*-----------header-css----------*/

header{
  background-color: transparent;
  position: absolute;
  width: 100%;
  z-index: 9;
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 72px;
}
.site-logo{
  min-width: 120px;
  z-index: 9;
}
.site-logo h2{
  font-size: 20px; 
  padding: 0;
  margin: 0;
  color: #ffffff;
}
.navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-links{
  display: flex;
  justify-content: flex-end;
  list-style: none;
  /* position: relative; */
}
.navbar a:hover,
.navbar a:focus{
  color: #EDCD1F;
}
.mobile-menu-icon{
  display: none;
}
.nav-links li{
  position: unset;
  padding: 15px 0;
}
.navbar a{
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  padding: 0 8px;
  text-align: left;
}
.submenu{
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 10%);
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  top: 60px;
  left: 0;
  width: 100%;
  min-width: 350px;
  z-index: 9;
  max-height: 90vh;
  overflow-y: auto;
}
.submenu .submenu_container{
  width: 100%;
  max-width: 1140px;
  margin: auto;
  padding: 0 15px;
}
.submenu .sublist{
  padding: 30px 15px;
}
.submenu .sublist h4{
  margin-bottom: 10px;
}
.submenu li{
  padding: 0;
}
.submenu li a{
  display: block;
  padding: 5px 0;
}
.submenu li a:hover,
.submenu li a:focus{
  color: #EDCD1F;
}
.nav-links li:hover .submenu,
.nav-links li:focus .submenu{
  opacity: 1;
  visibility: visible;
  transition: all 0.5s ease-out;
}
.site-header .menu-right ul{
  display: flex;
  align-items: center;
}
.site-header .menu-right .login_btn{
  padding: 10px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  background-color: #ffffff;
  color: #EDCD1F;
  font-weight: 600;
}
.site-header .menu-right li i{
  font-size: 18px;
  margin-right: 10px;
}

/*------------banner-section-css---------*/

.banner-section{
  background: url(./assets/e-banner.jpg) no-repeat;
  background-size: cover;
  min-height: 100vh;
  height: 100vh;
  padding: 0;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}
.banner-section::before{
  content: '';
  background-color: rgba(0, 0, 0, .5);
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.banner-section .row{
  display: block;
}
.banner-content{
  max-width: 650px;
  margin: auto;
}
.banner-content h2{
  font-size: 50px;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
}
.banner-content p{
  text-align: center;
}
.banner-content p{
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}
.search_form{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  margin-top: 40px;
  border-radius: 40px;
}
.search_form .form-control {
width: 100%;
height: 52px;
padding: 0 15px;
line-height: 52px;
color: #000000;
background-color: #ffffff;
border: none;
outline: none;
border-radius: 0;
-webkit-box-shadow: unset !important;
box-shadow: unset !important;
-webkit-transition: .5s;
transition: .5s;
font-size: 15px;
font-weight: 400;
border-radius: 4px;
margin-bottom: 0;
box-sizing: border-box;
}

.search_form input[type="submit"] {
  background: #EDCD1F;
  color: #ffffff;
  height: 52px;
  line-height: 52px;
  padding: 0 15px;
  font-weight: 600;
  border-radius: 4px;
  margin: 0;
  min-width: 112px;
}
.search_form input[type="submit"]:hover{
background: #ffffff;
color: #EDCD1F;
}

.upload{
  text-align: center;
}
.upload a{
  color: #ffffff;
  font-weight: 600;
}

/*----------served-section-css---------------*/
.key_customers{
   position: relative;
   z-index: 1;
   padding: 60px 0;
   background-color: #f7f7f7;
}
.key_customers .title{
  margin-bottom: 40px;
}
.key_customers .title h2{
  font-size: 1.5em;
  margin-bottom: 15px;
  text-align: center;
  line-height: 1.2em;
}
.key_customers .title .bar{
  margin-left: auto;
  margin-right: auto;
}
.key_customers .col{
  margin-bottom: 30px;
}
.key_customers .col .content{
  background: #ffffff;
  border-radius: 1rem;
  padding: 1.4rem 1rem;
  box-shadow: 0px 0px 25px 10px rgb(0 0 0 / 20%);
  text-align: center;
  height: 216px;
}
.key_customers .iconbox{
  background: rgba(8, 106, 216, .11);
  width: 60px;
  height: 60px;
  border-radius: 24px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
}
.key_customers .iconbox i{
  color: #000000;
  font-size: 24px;
}
.key_customers h2{
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #000000;
}
.key_customers p{
  font-size: 14px;
  line-height: 22px;
  margin: 0;
}
.key_customers .row .col:hover .iconbox{
  background: #EDCD1F;
} 
.key_customers .row .col:hover i{
  color: #ffffff;
}
.key_customers .item {
  padding: 0 10px;
}
.key_customers .item img {
  max-height: 80px;
  border: 1px solid #ebebeb;
  object-fit: contain;
}

/*-------------benifits-section-css------------*/
.benifits_section{
  padding: 60px 0;
  position: relative;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.benifits_section .row{
  align-items: center;
  margin-bottom: 40px;
}
.benifits_section img{
  width: 100%;
  object-fit: cover;
}
.benifits_section .row:last-child{
  margin-bottom: 0;
}

/*---------------services-section-css----------------*/
.services_section{
  padding: 60px 0;
  background-color: #ffffff;
  position: relative;
}
.section-title {
  text-align: center;
  margin-bottom: 60px;
}
.section-title .bar{
  margin-left: auto;
  margin-right: auto;
}
.services_section .col .single-services {
	text-align: center;
	background-color: #fff;
	padding: 0;
	border-radius: 0;
	position: relative;
	z-index: 1;
	-webkit-box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
	box-shadow: 0 5px 40px 0 rgba(0, 0, 0, .11);
  margin-bottom: 30px;
}
.single-services .text_block{
  padding: 15px 30px 30px;
  /* text-align: justify; */
}
.single-services h3 {
	font-size: 20px;
	margin: 0 0 10px;
	-webkit-transition: .5s;
	transition: .5s
}
.single-services p {
	margin-bottom: 0;
	-webkit-transition: .5s;
	transition: .5s;
	font-size: 14px;
}

/*---about-section-css----*/
.about_section{
  padding: 60px 0;
  position: relative;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}

/*---reviews-section-css----*/ 
.reviews_section{
  padding: 15px 0;
  position: relative;
  background-color: #EDCD1F;
}
.reviews_section ul{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.reviews_section ul li{
  padding: 0 30px;
  border-right: 1px solid rgba(255, 255, 255, .4);
  color: #3D5975;
  text-align: center;
}
.reviews_section ul li:last-child{
  border-right: none;
}
.reviews_section ul li h2{
  color: #3D5975;
  text-align: center;
}
.reviews_section ul li p{
  margin-bottom: 0;
  color: #3D5975;
  text-align: center;
}

.info_section{
  background-color: #212121;
  padding: 40px 0;
  border-bottom: 1px solid rgba(255, 255, 255, .2);
}
.info_section .info{
  display: flex;
  justify-content: center;
}
.info_section .left_block, 
.info_section .right_block{
  position: relative;
  padding: 0 70px; 
}
.info_section i{
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 40px;
  color: #ffffff;
}
.info_section h4{
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 0;
}
.info_section p{
  margin: 0;
  color: #ffffff;
}
.info_section a{
  color: #ffffff;
}

/*---------footer.css---------*/

.footer-section {
	background-color: #000000
}
.ft-grids{
  background: #212121;
  padding: 60px 0 20px;
  color: #ffffff;
}
.ft-grids .row{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
}
.single-footer-widget {
	margin-bottom: 30px
}
.single-footer-widget .footer-heading {
	margin-bottom: 20px
}

.single-footer-widget .footer-heading h3 {
	font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.single-footer-widget .footer-social {
	padding-left: 0;
	margin-bottom: 0
}

.single-footer-widget .footer-social li {
	display: inline-block;
	margin-right: 10px;
	list-style-type: none
}

.single-footer-widget .footer-social li:last-child {
	margin-right: 0
}

.single-footer-widget .footer-social i {
	display: inline-block;
	height: 35px;
	width: 35px;
	line-height: 35px;
	background-color: #EDCD1F;
	color: #fff;
	text-align: center;
	border-radius: 30px;
	font-size: 14px;
	-webkit-transition: .5s;
	transition: .5s
}

.single-footer-widget .footer-social i:hover {
	background-color: #10dce8
}

.single-footer-widget .footer-quick-links {
	padding-left: 0;
	margin-bottom: 0
}

.single-footer-widget .footer-quick-links li {
	list-style-type: none;
	margin-bottom: 12px;
	font-size: 15px;
	font-weight: 600
}

.single-footer-widget .footer-quick-links li:last-child {
	margin-bottom: 0
}
.single-footer-widget a{
  color: #ffffff;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
  font-weight: normal;
}
.single-footer-widget a:hover,
.single-footer-widget a:focus{
  color: #EDCD1F;
}
.single-footer-widget .footer-info-contact{
  position: relative;
  padding-left: 40px;
  margin-bottom: 16px;
}
.single-footer-widget .footer-info-contact i {
	position: absolute;
	left: 0;
  top: 2px;
	font-size: 20px;
}

.single-footer-widget .footer-info-contact h3 {
	margin-bottom: 5px;
	font-size: 15px;
	font-weight: 600;
  color: #ffffff;
}

.single-footer-widget .footer-info-contact span {
	font-size: 15px;
  color: #ffffff;
}

.copyright_text p{
  margin-bottom: 0;
  border-top: 1px solid rgba(255,255,255,.3);
  padding-top: 22px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}



/*-------------------media-query-css------------*/

@media (max-width:576px){
  .search_form{
    grid-template-columns: 1fr;
  }
  .banner-content h2 {
    font-size: 28px;
    line-height: 38px;
  }
  .served_section{
    margin-top: 0;
  }
  .served_section .row .col{
    margin-bottom: 25px;
  }
  .rect-shape{
    left: auto;
    right: 0;
  }
  .banner-section{
    height: 100%;
    padding-top: 100px;
  }
  .banner-content{
    margin-top: 0;
  }
  .about_section .col:first-child, 
  .moreinfo_section .col:first-child{
    margin-bottom: 30px;
  }
  .about_section{
    padding: 40px 0;
  }
  .circle-shape{
    left: auto;
    right: 2%;
  }
  .services_section{
    background-image: none;
  }
  .moreinfo_section .square-shape{
    width: 90px;
    top: 3%;
  }
  .moreinfo_section .circle-shape{
    width: 90px;
    bottom: 1%;
  }
  .partners_section ul{
    display: block;
  }
  .partners_section ul li{
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  .partners_section{
    padding-bottom: 40px;
  }
  .subscribe-area .subscribe-content{
    margin-bottom: 30px;
  }
  .subscribe-area .newsletter-form .input-newsletter{
    padding-left: 15px;
  }
  .subscribe-area .newsletter-form button{
    padding: 0 15px;
  }
  .ft-grids{
    padding-top: 40px;
  }
  .served_section .title h2{
    margin-bottom: 40px;
  }
  .services_section .section-title {
    margin-top: 0;
    margin-bottom: 0;
  }
  .services_section{
    padding-top: 0;
  }
  .services_section .row{
    padding-top: 40px;
  }
  .testimonial_slider .default-shape{
    display: none;
  }
  .testimonial_slider{
    padding-top: 0;
  }
  .default-shape{
    display: none;
  }
  .served_section .col .content, .services_section .col .single-services{
    height: 100%;
  }
  .services_section .col{
    margin-bottom: 30px;
  }
  .row{
    display: block !important;
  }
  .site-header .menu-right{
    padding-right: 40px;
  }
  .site-header .menu-right .login_btn{
    padding: 10px 5px;
  }
  .site-header .menu-right li i{
    margin-right: 0;
  }
  .site-header .menu-right li i{
    font-size: 14px;
  }
  .info_section .info{
    display: block;
  }
  .info_section i{
    font-size: 24px;
  }
  .info_section .left_block, .info_section .right_block{
    padding: 0 34px;
  }
  .info_section .left_block{
    margin-bottom: 30px;
  }
  .info_section p{
    font-size: 14px;
  }
}

@media (min-width:577px) and (max-width:767px){ 
  .about_section .row{
    display: block;
  }
  .about_section img{
    margin-bottom: 40px;
  }
  .services_section .row{
    padding-top: 0;
    grid-template-columns: 1fr 1fr;
  }
  .ft-grids .row{
    grid-template-columns: 1fr 1fr;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
  .info_section .left_block, .info_section .right_block{
    padding: 0 34px;
  }
  .info_section i{
    font-size: 24px;
  }
  .info_section p{
    font-size: 14px;
  }
}

@media (max-width:767px){
  .col {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important; 
  }
  .reviews_section ul li{
    border-right: none;
    margin-bottom: 30px;
  }
}
@media (min-width:767px) and (max-width:1023px){
  .col {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important; 
  }
  .col.col12, .ft-grids .col {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important; 
  }
  .ft-grids .row{
    grid-gap: 0;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
  .info_section .left_block, .info_section .right_block{
    padding: 0 34px;
  }
  .info_section i{
    font-size: 24px;
  }
  .info_section p{
    font-size: 14px;
  }
  .reviews_section ul li{
    padding: 0 15px;
  }
}
@media (min-width:1023px) and (max-width:1199px){
  .services_section .col .single-services{
    height: 460px;
  }
  .site-header .menu-right{
    padding-right: 60px;
  }
}

@media screen and (max-width: 1199px){
  .nav-links{
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  .nav-links-mobile{
    position: absolute;
    opacity: 1;
    visibility: visible;
    list-style: none;
    background-color: #212121;
    left: 0;
    top: 72px;
    transition: all 0.5s ease-out;
    width: 100%;
    height: 100vh;
    padding: 0 0 20px;
    margin: 0;
    z-index: 8;
    overflow-y: scroll;
  }
  .mobile-menu-icon{
    display: block;
    position: absolute;
    font-size: 30px;
    color: #ffffff;
    background-color: transparent;
    border: none;
    outline: none;
    top: 20px;
    right: 15px;
    z-index: 9;
  }
  .nav-links-mobile
   a{
    padding: 10px 15px;
    width: 100%;
    display: block;
  }
  .nav-links-mobile .submenu{
    position: relative;
    opacity: 1;
    visibility: visible;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    min-width: 100%;
    top: 0;
    max-height: 100%;
  }
  .submenu li a{
    padding: 10px 15px; 
  }
  .submenu li:first-child a{
    padding-top: 10px; 
  }
  .submenu li:last-child a{
    padding-bottom: 10px;
  }
  .submenu .sublist {
    padding: 5px 15px;
  }
  .submenu .sublist li a{
    padding-left: 0;
    padding-right: 0;
  }
  .site-header .menu-right{
    display: none;
  }
} 

.floating { 
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 5px;
}

@keyframes floating {
  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }   
}

.searchBarMain {
 background-color: white;
 border-radius: 30px;
}

table {
  /* font-family: arial, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

.input-text-field {
  margin: 10px 0 !important;
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
}
